document.addEventListener('click', event => {
  let target = event.target as HTMLElement;
  const parent = target?.closest('[data-toggle]');
  if (target.nodeName !== 'A' && parent !== null) {
    target = parent as HTMLElement;
  }

  if (!target.hasAttribute('data-toggle')) {
    return;
  }

  event.preventDefault();
  requestAnimationFrame(() => {
    switch (target.getAttribute('data-toggle')) {
      case 'seo':
      case 'teaser-content':
      case 'offcanvas-item':
        target.parentElement.classList.toggle('open');
        break;
      case 'share':
        document.querySelector('body').classList.toggle('socialmedia-share-active');
        break;
      case 'country-select':
        document.querySelector('body').classList.toggle('country-select-active');
        break;
    }
  });
});
