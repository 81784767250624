const sendGa = (command: 'event', eventName: Gtag.EventNames | (string & {}), eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams) => {
  if (typeof gtag === 'function') {
    gtag(command, eventName, eventParams || {});
  }
};
document.addEventListener('click', event => {
  let target = event.target as HTMLElement;
  const parent = target?.closest('a[ga-on]');
  if (target.nodeName !== 'A' && parent !== null) {
    target = parent as HTMLElement;
  }

  if (target.nodeName !== 'A') {
    return;
  }
  checkForAnchorLink(target);
  checkForLinkTracking(target);
  checkForFileDownloadLink(target);
});

function checkForAnchorLink(target: HTMLElement) {
  if (!target.hasAttribute('href')) {
    return;
  }

  const link = target.getAttribute('href') || '';
  if (!link.includes('#')) {
    return;
  }

  sendGa('event', 'click', {
    link_text: target.innerText,
    link_category: 'Anchor Link',
    link_action: 'click',
    link_url: link,
    link_anchor: link.split('#')[1],
  });
}

function checkForLinkTracking(target: HTMLElement) {
  if (!target.hasAttribute('ga-on')) {
    return;
  }

  const eventName = target.getAttribute('ga-on') || 'click';
  sendGa('event', eventName, {
    link_text: target.getAttribute('ga-event-label') || target.innerText,
    link_category: target.getAttribute('ga-event-category') || 'CTA',
    link_action: target.getAttribute('ga-event-action') || 'click',
    link_url: target.getAttribute('href') || '',
  });
}

// File downloads (file_download) https://support.google.com/analytics/answer/9216061?hl=en
function checkForFileDownloadLink(target: HTMLElement) {
  if (!target.hasAttribute('href')) {
    return;
  }

  const link = target.getAttribute('href') || '';

  const [fileExtension] = link.match(new RegExp('pdf|xlsx?|docx?|txt|rtf|csv|exe|key|pp(s|t|tx)|7z|pkg|rar|gz|zip|avi|mov|mp4|mpe?g|wmv|midi?|mp3|wav|wma', 'g')) || [];
  if (!fileExtension) {
    return;
  }

  const fileName = link.split('/').pop();

  sendGa('event', 'file_download', {
    file_extension: fileExtension,
    file_name: fileName,
    link_classes: target.className,
    link_id: target.id,
    link_text: target.innerText,
    link_url: link,
  });
}

// Scroll depth tracking
let maxScrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
const percentagesArr = [20, 30, 40, 50, 60, 70, 80, 100];
const showed = {};
let previousPercentage = 0;
window.addEventListener(
  'scroll',
  () => {
    const scrollVal = window.scrollY;
    const scrollPercentage = Math.round((scrollVal / maxScrollHeight) * 100);
    let currentPercentage = 0;
    let i = 0;
    while (percentagesArr[i] <= scrollPercentage) {
      currentPercentage = percentagesArr[i++];
    }
    if (previousPercentage !== currentPercentage) {
      previousPercentage = currentPercentage;
      if (!showed[currentPercentage]) {
        showed[currentPercentage] = true;
        sendGa('event', 'scroll', {
          percent_scrolled: currentPercentage,
        });
      }
    }
  },
  { passive: true },
);

window.addEventListener(
  'resize',
  () => {
    maxScrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  },
  { passive: true },
);
